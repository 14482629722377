import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="inner">
                    <ul className="icons">
                        <li><a href="https://m.me/352342260188?fbclid=IwAR1ZKKND29iB14_wtk0s0umTwtToK6WN-NPm3I3LJaKeFpn29dL8JGrSWmo" className="icon fa-comments"><span className="label">Twitter</span></a></li>
                        <li><a href="https://www.facebook.com/pg/Mow-Plus-Garden-Services-352342260188/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
                        <li><a href="https://www.google.ie/maps/place/Mowplus+Garden+Services/@53.5761831,-6.1038447,19z/data=!3m1!4b1!4m14!1m6!3m5!1s0x486718a42c81cda5:0xb0b62042499529d7!2sMowplus+Garden+Services!8m2!3d53.5761823!4d-6.1032975!3m6!1s0x486718a42c81cda5:0xb0b62042499529d7!8m2!3d53.5761823!4d-6.1032975!9m1!1b1" className="icon fa-map-marker"><span className="label">Dribbble</span></a></li>
                        <li><a href="mailto:mowplus@gmail.com?subject=website enquiry" className="icon fa-envelope-o"><span className="label">Email</span></a></li>
                    </ul>
                    <ul className="copyright">
                        <li>&copy; MowPlus</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
