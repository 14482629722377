import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/mowplus.svg'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="https://www.mowplus.ie" className="image avatar"><img src={avatar} alt="" /></a>
                    <h1><strong>Mow Plus +</strong></h1>
                    <h2>Gardening and landscaping services</h2>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
